/*
 Purpose: Displays Admin users details in UI
 Version History :
 Date              Name             -      Reason for modification 
 27-May-2024     Pankaj Pal                IVPSD-4891
 20-Jan-2025	   Naresh Ginjupalli		     IVPSD-7537
*/
export const DEFAULT_FILTER_MODEL = {
  fromDate: null,
  toDate: null,
  subFilters: {
    FAERS: {
      type: 'FAERS',
      //IVPSD-4891
      ageGroup: [
        {
          name: 'Fetus',
          label: 'Fetus',
          tooltip: 'Fetus (0)'
        },
        {
          name: 'Neonate',
          label: 'Neonate',
          tooltip: 'Neonate (0 - 28d)'
        },

        {
          name: 'Infant',
          label: 'Infant',
          tooltip: 'Infant (29d - 1y)'
        },
        {
          name: 'Child',
          label: 'Child',
          tooltip: 'Child (2y - 11y)'
        },
        {
        name: 'Adolescent',
          label: 'Adolescent',
          tooltip: 'Adolescent (12y - 17y)'
        },
        {
          name: 'Adult',
          label: 'Adult',
          tooltip: 'Adult (18y - 64y)'
        },
        {
          name: 'Elderly',
          label: 'Elderly',
          tooltip: 'Elderly (65y+)'
        },
        {
          name: 'Not_Provided',
          label: 'Not Provided',
          tooltip: 'Not Provided'
          }

      ],
      gender: [
        {
          name: 'MALE',
          label: 'Male'
        },
        {
          name: 'FEMALE',
          label: 'Female'
        },
        {
          name: 'NOT_PROVIDED',
          label: 'Not Provided'
        },
      ],
      outcome: [
        {
          name: 'OTHER',
          label: 'Other'
        },
        {
          name: 'DEATH',
          label: 'Death'
        },
        {
          name: 'DISABILITY',
          label: 'Disability'
        },
        {
          name: 'NOT_PROVIDED',
          label: 'Not Provided'
        },
        {
          name: 'INTERVENTION',
          label: 'Required Intervention'
        },
        {
          name: 'HOSPITALIZATION',
          label: 'Hospitalization'
        },
        {
          name: 'LIFE_THREATENING',
          label: 'Life Threatening'
        },
        {
          name: 'CONGENITAL_ANOMALY' + '',
          label: 'Congenital Anomaly'
        },
      ],
      dataSource: [
        {
          name: 'OTHER',
          label: 'Other'
        },
        {
          name: 'STUDY',
          label: 'Study'
        },
        {
          name: 'FOREIGN',
          label: 'Foreign'
        },
        {
          name: 'CONSUMER',
          label: 'Consumer'
        },
        {
          name: 'LITERATURE',
          label: 'Literature'
        },
        {
          name: 'DISTRIBUTOR',
          label: 'Distributor'
        },
        {
          name: 'NOT_PROVIDED',
          label: 'Not Provided'
        },
        {
          name: 'USER_FACILITY',
          label: 'User Facility'
        },
        {
          name: 'HEALTH_PROFESSIONAL',
          label: 'Health Professional'
        },
        {
          name: 'COMPANY_REPRESENTATIVE' + '',
          label: 'Company Representative'
        },
      ],
      submitter : [
        {
          name: 'LAWYER',
          label: 'Lawyer'
        },
        {
          name: 'CONSUMER',
          label: 'Consumer'
        },
        {
          name: 'PHYSICIAN',
          label: 'Physician'
        },
        {
          name: 'PHARMACIST',
          label: 'Pharmacist'
        },
        {
          name: 'NOT_PROVIDED',
          label: 'Not Provided'
        },
        {
          name: 'OTHER_HEALTH_PROFESSIONAL' + '',
          label: 'Other Health Professional'
        },
      ]
    },
    ARGUS: {
      type: 'ARGUS',
      // IVPSD-4891
      ageGroup: [
        {
          name: 'Fetus',
          label: 'Fetus',
          tooltip: 'Fetus (0)'
        },
        {
          name: 'Neonate',
          label: 'Neonate',
          tooltip: 'Neonate (0 - 28d)'
        },

        {
          name: 'Infant',
          label: 'Infant',
          tooltip: 'Infant (29d - 1y)'
        },
        {
          name: 'Child',
          label: 'Child',
          tooltip: 'Child (2y - 11y)'
        },
        {
        name: 'Adolescent',
          label: 'Adolescent',
          tooltip: 'Adolescent (12y - 17y)'
        },
        {
          name: 'Adult',
          label: 'Adult',
          tooltip: 'Adult (18y - 64y)'
        },
        {
          name: 'Elderly',
          label: 'Elderly',
          tooltip: 'Elderly (65y+)'
        },
        {
          name: 'Not_Provided',
            label: 'Not Provided',
            tooltip: 'Not Provided'
        }
      ],
      gender: [
        {
          name: 'MALE',
          label: 'Male'
        },
        {
          name: 'FEMALE',
          label: 'Female'
        },
        {
          name: 'NOT_PROVIDED',
          label: 'Not Provided'
        },
      ]
    },
    VAERS: {
      type: 'VAERS',
      // IVPSD-4891
      ageGroup: [
        {
          name: 'Fetus',
          label: 'Fetus',
          tooltip: 'Fetus (0)'
        },
        {
          name: 'Neonate',
          label: 'Neonate',
          tooltip: 'Neonate (0 - 28d)'
        },

        {
          name: 'Infant',
          label: 'Infant',
          tooltip: 'Infant (29d - 1y)'
        },
        {
          name: 'Child',
          label: 'Child',
          tooltip: 'Child (2y - 11y)'
        },
        {
        name: 'ADOLESCENT',
          label: 'Adolescent',
          tooltip: 'Adolescent (12y - 17y)'
        },
        {
          name: 'Adult',
          label: 'Adult',
          tooltip: 'Adult (18y - 64y)'
        },
        {
          name: 'Elderly',
          label: 'Elderly',
          tooltip: 'Elderly (65y+)'
        },
        {
          name: 'Not_Provided',
            label: 'Not Provided',
            tooltip: 'Not Provided'
          }
      ],
      gender: [
        {
          name: 'MALE',
          label: 'Male'
        },
        {
          name: 'FEMALE',
          label: 'Female'
        },
        {
          name: 'NOT_PROVIDED',
          label: 'Not Provided'
        },
      ],
      outcome: [
        {
          name: 'DIED',
          label: 'Died'
        },
        {
          name: 'X_STAY',
          label: 'Prolonged Hospitalization'
        },
        {
          name: 'DISABLE',
          label: 'Disability'
        },
        {
          name: 'ER_VISIT',
          label: 'Emergency Room'
        },
        {
          name: 'HOSPITAL',
          label: 'Hospitalized'
        },
        {
          name: 'L_THREAT',
          label: 'Life Threatening'
        },
        {
          name: 'OFC_VISIT',
          label: 'Doctor visit'
        },
        {
          name: 'ER_ED_VISIT',
          label: 'Urgent care'
        },
        {
          name: 'NOT_PROVIDED',
          label: 'Not Provided'
        },
        {
          name: 'BIRTH_DEFECT',
          label: 'Congenital Anomaly'
        },
      ]
    },
    IVP: {
      type: 'IVP',
      // IVPSD-4891
      ageGroup: [
        {
          name: 'Fetus',
          label: 'Fetus',
          tooltip: 'Fetus (0)'
        },
        {
          name: 'Neonate',
          label: 'Neonate',
          tooltip: 'Neonate (0 - 28d)'
        },

        {
          name: 'Infant',
          label: 'Infant',
          tooltip: 'Infant (29d - 1y)'
        },
        {
          name: 'Child',
          label: 'Child',
          tooltip: 'Child (2y - 11y)'
        },
        {
        name: 'Adolescent',
          label: 'Adolescent',
          tooltip: 'Adolescent (12y - 17y)'
        },
        {
          name: 'Adult',
          label: 'Adult',
          tooltip: 'Adult (18y - 64y)'
        },
        {
          name: 'Elderly',
          label: 'Elderly',
          tooltip: 'Elderly (65y+)'
        },
        {
          name: 'Not_Provided',
          label: 'Not Provided',
          tooltip:'Not Provided'
        }
      ],
      gender: [
        {
          name: 'MALE',
          label: 'Male'
        },
        {
          name: 'FEMALE',
          label: 'Female'
        },  
        {
          name: 'NOT_PROVIDED',
          label: 'Not Provided'
        },
      ],
      outcome: [
        {
          name: 'OTHER',
          label: 'Other'
        },
        {
          name: 'BIRTH_DEFECT',
          label: 'Congenital Anomaly'
        },
        {
          name: 'MEDICALLY_SIGNIFICANT',
          label: 'Medically Significant'
        },
        {
          name: 'DIED',
          label: 'Died'
        },
        {
          name: 'INTERVENTION_REQUIRED',
          label: 'Required Intervention'
        },
        {
          name: 'DISABLE',
          label: 'Disability'
        },
        {
          name: 'HOSPITAL',
          label: 'Hospitalization'
        },
        {
          name: 'L_THREAT',
          label: 'Life Threatening'
        },
        {
          name: 'NOT_PROVIDED',
          label: 'Not Provided'
        },
      ],
      dataSource: [
        {
          name: 'NIS',
          label: 'Nis'
        },
        {
          name: 'PMS',
          label: 'Pms'
        },
        {
          name: 'OTHER',
          label: 'Other'
        },
        {
          name: 'STUDY',
          label: 'Study'
        },
        {
          name: 'REGISTRY',
          label: 'Registry'
        },
        {
          name: 'LITERATURE',
          label: 'Literature'
        },
        {
          name: 'SPONTANEOUS',
          label: 'Spontaneous'
        },
        {
          name: 'SOCIAL_MEDIA',
          label: 'Social Media'
        },
        {
          name: 'NOT_PROVIDED',
          label: 'Not Provided'
        },
        {
          name: 'COMPASSIONATE_USE',
          label: 'Compassionate Use'
        },
        {
          name: 'REGULATORY_AUTHORITY',
          label: 'Regulatory Authority'
        },
      ],
      submitter : [
        {
          name: 'LAWYER',
          label: 'Lawyer'
        },
        {
          name: 'PHYSICIAN',
          label: 'Physician'
        },
        {
          name: 'PHARMACIST',
          label: 'Pharmacist'
        },
        {
          name: 'NOT_PROVIDED',
          label: 'Not Provided'
        },
        {
          name: 'OTHER_HEALTH_PROFESSIONAL' + '',
          label: 'Other Health Professional'
        },
        {
          name: 'CONSUMER',
          label: 'Consumer or Other Non Health Professional'
        },
      ]
    },
    EVDAS: {
      type: 'EVDAS',
      ageGroup: [
        {
          name: 'Foetus',
          label: 'Foetus',
          tooltip: 'Foetus (0)'
        },
        {
          name: 'Neonate',
          label: 'Neonate',
          tooltip: 'Neonate (0 - 28d)'
        },

        {
          name: 'Infant',
          label: 'Infant',
          tooltip: 'Infant (29d - 1y)'
        },
        {
          name: 'Child',
          label: 'Child',
          tooltip: 'Child (2y - 11y)'
        },
        {
        name: 'Adolescent',
          label: 'Adolescent',
          tooltip: 'Adolescent (12y - 17y)'
        },
        {
          name: 'Adult',
          label: 'Adult',
          tooltip: 'Adult (18y - 64y)'
        },
        {
          name: 'Elderly',
          label: 'Elderly',
          tooltip: 'Elderly (65y+)'
        },
        {
          name: 'Not_Provided',
          label: 'Not Provided',
          tooltip:'Not Provided'
        }
      ],
      gender: [
        {
          name: 'MALE',
          label: 'Male'
        },
        {
          name: 'FEMALE',
          label: 'Female'
        },  
        {
          name: 'NOT_PROVIDED',
          label: 'Not Provided'
        },
      ]      
    }
  }
};
